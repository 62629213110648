import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-blue-600 text-white py-4 sticky top-0 z-50 shadow">
      <div className="container mx-auto flex justify-between items-center">
        {/* Left Side: Logo */}
        <div className="flex items-center">
          <img src="/logo192.png" alt="Logo" className="h-10 mr-2" />
          
        </div>

        {/* Middle: Company Name */}
        <div className="text-2xl font-bold">Kuwait Flight</div>

        {/* Right Side: Menu */}
        <nav>
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="hover:text-blue-200">Home</Link>
            </li>
            <li>
              <Link to="/search" className="hover:text-blue-200">Search Flights</Link>
            </li>
            <li>
              <Link to="/booking" className="hover:text-blue-200">Booking</Link>
            </li>
            <li>
              <Link to="/confirmation" className="hover:text-blue-200">Sign Up</Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-blue-200">Sign In</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
