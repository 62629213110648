import React from 'react';

const HomePage = () => {
  return (
    <div
      className="flex flex-col items-center justify-center h-screen"
      style={{
        backgroundImage: 'url(/background.gif)', // Ensure this path is correct
        backgroundSize: 'cover', // Ensures the background covers the entire area
        backgroundPosition: 'center', // Centers the background image
        backgroundRepeat: 'no-repeat' // Prevents the background from repeating
      }}
    >
      <h1 className="text-3xl font-bold text-slate-800 mb-6 max-w-4xl">Flight Booking</h1>

      <form className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-1">
            <label className="block text-gray-700">Departure</label>
            <input type="text" placeholder="Enter departure city" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400" />
          </div>
          <div className="col-span-1">
            <label className="block text-gray-700">Destination</label>
            <input type="text" placeholder="Enter destination city" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400" />
          </div>
          <div className="col-span-1">
            <label className="block text-gray-700">Departure Date</label>
            <input type="date" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400" />
          </div>
          <div className="col-span-1">
            <label className="block text-gray-700">Travelers</label>
            <input type="number" min="1" placeholder="Number of travelers" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400" />
          </div>
        </div>

        <div className="flex justify-center mt-6">
          <button type="submit" className="bg-blue-600 text-white py-2 px-6 rounded-full hover:bg-blue-700 transition-colors">
            Search Flights
          </button>
        </div>
      </form>
    </div>
  );
};

export default HomePage;
