import React from 'react';

const Footer = () => {
  return (
    <div className="bg-gray-100 p-6">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Column 1 */}
        <div>
          <h4 className="font-bold text-gray-700 mb-2">Company</h4>
          <ul>
            <li><a href="/about" className="text-gray-600 hover:text-blue-600">About Us</a></li>
            <li><a href="/services" className="text-gray-600 hover:text-blue-600">Services</a></li>
            <li><a href="/contact" className="text-gray-600 hover:text-blue-600">Contact</a></li>
          </ul>
        </div>

        {/* Column 2 */}
        <div>
          <h4 className="font-bold text-gray-700 mb-2">Help</h4>
          <ul>
            <li><a href="/faq" className="text-gray-600 hover:text-blue-600">FAQ</a></li>
            <li><a href="/support" className="text-gray-600 hover:text-blue-600">Support</a></li>
            <li><a href="/privacy" className="text-gray-600 hover:text-blue-600">Privacy Policy</a></li>
          </ul>
        </div>

        {/* Column 3 */}
        <div>
          <h4 className="font-bold text-gray-700 mb-2">Follow Us</h4>
          <ul>
            <li><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600">LinkedIn</a></li>
            <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600">Facebook</a></li>
            <li><a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600">Instagram</a></li>
          </ul>
        </div>

        {/* Column 4 */}
        <div>
          <h4 className="font-bold text-gray-700 mb-2">Contact</h4>
          <ul>
            <li><span className="text-gray-600">Email: support@kuwaitflits.com</span></li>
            <li><span className="text-gray-600">Phone: +965 1234 5678</span></li>
            <li><span className="text-gray-600">Address: Kuwait City, Kuwait</span></li>
          </ul>
        </div>
      </div>
      
      {/* Footer Bottom */}
      <div className="mt-6 text-center text-gray-600">
        <p>© 2024 Kuwait Flits Online. All rights reserved. Version 1.0</p>
        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </div>
    </div>
  );
};

export default Footer;
